import React, { useState } from 'react'

export default function Accordion({id, title, children}) {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }
    // const {id, title} = props;
  return (
        <div className="accordion-item card">
            <h2 className="accordion-header d-flex align-items-center">
                <button type="button" className={`accordion-button${collapsed ? '' : ' collapsed'}`} data-bs-toggle="collapse" data-bs-target={`accordionWithIcon-${id}`} aria-expanded={`${collapsed ? 'true' : 'false'}`} onClick={toggleCollapse}>
                    <i className="bx bx-bar-chart-alt-2 me-2"></i>
                    <p className="fs-13 fw-semibold mb-0">{title}</p>
                </button>
            </h2>

            <div id={`accordionWithIcon-${id}`} className={`accordion-collapse collapse${collapsed ? ' show' : ''}`}>
                <div className="accordion-body">
                    <div className="table-responsive text-nowrap">
                        {children}
                    </div>
                </div>
            </div>
        </div>
  )
}
