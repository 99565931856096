import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./pages/auth/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TwoFactorAuth from "./pages/auth/TwoFactorAuth";
import Users from "./pages/users/Users";
import Rewards from "./pages/rewards/Rewards";
import Auth from "./utils/Auth";
import Offers from "./pages/offerings/Offers";
import AddRewardRule from "./pages/rewards/AddRewardRule";
import Addons from "./pages/addons/Addons";
import CreateAddon from "./pages/addons/CreateAddon";
import MenuItems from "./pages/menu/MenuItems";
import PushNotifications from "./pages/push_notifications/PushNotifications";
import AddNotification from "./pages/push_notifications/AddNotification";
import EditNotification from "./pages/push_notifications/EditNotification";
import AddRule from "./pages/offerings/AddRules";
import Payments from "./pages/payments/Payment";
import EditRule from "./pages/offerings/EditRules";
import StripeIntegration from "./pages/stripe/StripeIntegration";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import ProfileSettings from "./pages/profile/ProfileSettings";
import ChangePassword from "./pages/profile/ChangePassword";
import AddMenuItems from "./pages/menu/AddMenuItems";
import AccountDeleteRequest from "./pages/auth/AccountDeleteRequest";

function App() {
  const { user } = useContext(AuthContext);
  const router = createBrowserRouter([
    { path: "/", element: <Login /> },
    { path: "/two-factor-auth", element: <TwoFactorAuth /> },
    { path: "/account-delete-request", element: <AccountDeleteRequest /> },
    ...(user?.user?.role === 1
      ? [
          {
            path: "/users",
            element: (
              <Auth title="User Management" adminOnly={true}>
                <Users />
              </Auth>
            ),
          },
          {
            path: "/push-notifications",
            element: (
              <Auth title="Push Notifications">
                <PushNotifications />
              </Auth>
            ),
          },
          {
            path: "/add-notification",
            element: (
              <Auth title="Add Notifications">
                <AddNotification />
              </Auth>
            ),
          },
          {
            path: "/edit-notification",
            element: (
              <Auth title="Edit Notifications">
                <EditNotification />
              </Auth>
            ),
          },
        ]
      : []),
    {
      path: "/rewards",
      element: (
        <Auth title="Rewards">
          <Rewards />
        </Auth>
      ),
    },
    {
      path: "/add-reward-rule",
      element: (
        <Auth title="Add Rule">
          <AddRewardRule />
        </Auth>
      ),
    },
    {
      path: "/latest-offerings",
      element: (
        <Auth title="Latest Offerings">
          <Offers />
        </Auth>
      ),
    },
    {
      path: "/stripe",
      element: (
        <Auth title="Stripe Integration">
          <StripeIntegration />
        </Auth>
      ),
    },
    {
      path: "/payments",
      element: (
        <Auth title="Payments">
          <Payments />
        </Auth>
      ),
    },
    {
      path: "/update-offer",
      element: (
        <Auth title="Update Offer">
          {/* <UpdateRule /> */}
          <EditRule />
        </Auth>
      ),
    },
    {
      path: "/add-offer",
      element: (
        <Auth title="Add Offer">
          <AddRule />
        </Auth>
      ),
    },
    {
      path: "/addons",
      element: (
        <Auth title="Addons">
          <Addons />
        </Auth>
      ),
    },
    {
      path: "/create-addons",
      element: (
        <Auth title="Create Addons">
          <CreateAddon />
        </Auth>
      ),
    },
    {
      path: "/menu-items",
      element: (
        <Auth title="Menu Items">
          <MenuItems />
        </Auth>
      ),
    },
    {
      path: "/add-menu-items",
      element: (
        <Auth title="Menu Items">
          <AddMenuItems />
        </Auth>
      ),
    },
    {
      path: "/profile",
      element: (
        <Auth title="Edit Profile">
          <ProfileSettings />
        </Auth>
      ),
    },
    {
      path: "/change-password",
      element: (
        <Auth title="Change Password">
          <ChangePassword />
        </Auth>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
